<template>
  <div class="w-full h-28 flex flex-col items-center">
    <div
      v-if="options"
      class="inline-block cursor-default text-xs text-primary px-3.75 py-3 rounded border border-primary-300 bg-primary-100"
    >
      {{id}}
    </div>
    <div
      v-else
      @click="options=true"
      class="inline-block cursor-pointer text-xs text-primary px-3.75 py-3 rounded border border-primary-300 hover:bg-primary-100"
    >
     {{id}}
    </div>

    <div v-show="options" class="mt-3 ">
      <label
        v-show="!file.name"
        :for="'uploadFile'+id"
        class="inline-block cursor-pointer text-primary px-4 py-2 rounded border border-primary-300 hover:bg-primary-100"
      >
        <LaptopIcon class="h-6 w-6"/>
      </label>
      <div
        v-show="!file.name"
        :for="'uploadFile'+id"
        @click="openDropbox"
        class="ml-2.5 inline-block cursor-pointer text-primary px-4.75 py-2.75 rounded border border-primary-300 hover:bg-primary-100"
      >
        <DropboxIcon class="h-4.5 w-4.5"/>
      </div>
      <input
        class="hidden"
        :id="'uploadFile'+id"
        accept="audio/*,video/*"
        type="file"
        value="upload"
        @change="checkFile($event)"
      >
    </div>

    <div
      @click="$parent.cancelItemId=id"
      v-show="task.state_==='success'"
      class="flex items-center"
    >
      <CheckIcon class="text-green h-6 w-6 mr-1" />
      <div class="text-xs">{{file.name}}</div>
    </div>

    <div
      @click="$parent.cancelItemId=id"
      v-show="task.state_==='running'"
      class="flex items-center"
    >
      <FileUploadIcon class="text-blue h-6 w-6 mr-1" />
      <div class="text-xs">
        Uploading {{((task.transferred_/task.blob_.size_)*100).toFixed(2)}}%
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import CONSTANTS from '@/constants/CONSTANTS'
import store from '@/services/store'

import LaptopIcon from '@/components/base/icons/Laptop.vue'
import DropboxIcon from '@/components/base/icons/Dropbox.vue'
import CheckIcon from '@/components/base/icons/Check.vue'
import FileUploadIcon from '@/components/base/icons/FileUpload.vue'

const uuidv1 = require('uuid/v1')
require('@/assets/js/dropins.js')
export default {
  name: 'uploadItem',
  props: ['id'],
  components: {
    LaptopIcon,
    DropboxIcon,
    CheckIcon,
    FileUploadIcon
  },
  data () {
    return {
      file: {},
      options: false,
      source: '',
      task: {
        state: '',
        blob_: {
          size_: ''
        }
      },
      podId: '',
      userId: '',
      seconds: '',
      fileId: uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1') + this.id
    }
  },
  computed: {
    billing () {
      return store.state.app.userInfo.billing
    }
  },
  watch: {
    task: {
      handler: function (oldVal, newVal) {
        // console.log('task changed')
        var vm = this
        if (vm.task.state_ === 'success') {
          this.$parent.files[this.id] = vm.fileId
        } else {
          this.$parent.files[this.id] = ''
        }
      },
      deep: true
    }
  },
  mounted () {
    // console.log('mounted upload item')
  },
  beforeDestroy () {
    // console.log('destroying upload-item')
  },
  methods: {
    clear () {
      var vm = this
      this.file = {}
      this.options = false
      $('#uploadFile' + this.id)[0].value = ''
      vm.task = {
        blob_: {
          size_: ''
        }
      }
      this.$parent.files[this.id] = ''
      this.$parent.mergedTrack = false
      this.$parent.clearSound()
      this.removeFileLink()
    },
    removeFileLink () {
      var vm = this
      if (vm.userId === '' || vm.podId === '') {
        return
      }
      var path = vm.userId + '/podcast-info/' + vm.podId + '/audio_url'
      console.log('path is', path)
      firebase.database().ref(path).remove()
    },
    uploadFile () {
      var vm = this
      console.log('--------------------')
      console.log('file', vm.file)
      console.log('id', vm.fileId)
      console.log('--------------------')
      var metadata = {
        'contentType': vm.file.type
      }
      vm.storageRef = firebase.storage().ref('podcast_audio/' + this.userId + '/uploaded_Tracks/' + this.podId + '/' + vm.fileId)
      vm.task = vm.storageRef.put(vm.file, metadata)
    },
    cancelUpload () {
      var vm = this
      if (vm.task.state_ === 'running') {
        vm.task.cancel()
      }
      if (vm.task.state_ === 'success') {
        vm.storageRef.delete()
      }
      vm.fileId = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1') + vm.id
      vm.clear()
    },
    setMetaData () {
      this.seconds = this.billing.seconds
      this.userId = this.$parent.user.uid
      this.podId = this.$parent.podId
    },
    checkFile (e) {
      e.preventDefault()
      // console.log(e)
      var vm = this
      vm.setMetaData()
      if (this.seconds <= 0) {
        this.$parent.status = 201
        return
      }
      this.file = e.target.files[0]
      console.log(this.file)
      if ((((e.target.files[0].size / 1024) / 1024) / 1024) > 1) {
        this.$parent.status = 501
        return
      }
      console.log('starting duration check')
      this.durationCheck('file')
        .then(function (response) {
          console.log(response)
          vm.urlValid = true
          vm.uploadFile()
          // vm.$emit('addToUploadList', vm.file, vm.id)
          setTimeout(function () {
            $('.ui.upload.modal').modal('refresh')
          }, 100)
        }).catch(function (err) {
          if (err) {
            console.log(err)
            console.log('duration not valid;file')
          }
        })
    },
    durationCheck (type) {
      // This method doesnt not work for every url(avi, mpg and not audio/video links). which is fine
      // becuase we are still able to remove othrs
      var vm = this
      vm.urlValid = vm.url
      return new Promise((resolve, reject) => {
        vm.duration = 0
        var video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = function () {
          // window.URL.revokeObjectURL(this.src)
          vm.duration = Math.ceil(video.duration)
          console.log(this.src)
          console.log(video.duration)
          resolve('Ok')
        }
        video.onerror = function () {
          window.URL.revokeObjectURL(this.src)
          console.log('err:' + this.src)
          resolve('Ok')
        }
        if (type === 'file') {
          console.log('file duration check')
          video.src = window.URL.createObjectURL(vm.file)
        } else {
          console.log('url duration check')
          video.src = vm.url
        }
      })
    },
    openDropbox () {
      var vm = this
      var options = {
        success: function (files) {
          console.log('info', files[0])
          console.log("Here's the file link: " + files[0].link)
          vm.url = files[0].link
          vm.file = files[0]
          vm.urlValid = true
          vm.$parent.files[vm.id] = files[0].link
          vm.task.state_ = 'success'
          // vm.checkURLNow(files[0].name)
        },
        linkType: 'direct',
        extensions: ['audio', 'video']
      }
      window.Dropbox.choose(options)
    },
    checkURLNow (title) {
      console.log('check url')
      this.urlTitle = ''
      if (this.url === '') {
        // Test here
        // this.status=200
        this.urlValid = false
        return
      }
      if (this.billing.seconds <= 0) {
        this.$parent.status = 201
        return
      }
      if (this.urlValid === this.url) {
        console.log('same url entered, so returning')
        return
      } else {
        if (this.source !== '') {
          this.source.cancel('New url entered so canceling this one.')
        }
      }
      var vm = this
      vm.setMetaData()
      this.durationCheck('url').then(function (response) {
        console.log(response)
        var CancelToken = axios.CancelToken
        vm.source = CancelToken.source()
        return axios({
          method: 'post',
          url: CONSTANTS.IS_VALID,
          data: {
            'userid': vm.userId,
            'link': vm.url
          },
          cancelToken: vm.source.token
        })
      }).then(function (response) {
        var data = response['data']
        console.log(data)
        if (data['status'] === 200) {
          if (title !== undefined) {
            vm.urlTitle = title
          } else {
            vm.urlTitle = data['title']
          }
          vm.urlValid = true
          vm.$emit('addToUploadList', vm.url, vm.id)
          setTimeout(function () {
            $('.ui.upload.modal').modal('refresh')
          }, 100)
        } else if (data['status'] === 201) {
          vm.duration = Math.ceil(data['duration'])
          // vm.urlValid=false
          // vm.status=data['status']
        } else if (vm.uploadState[data['status']] !== '') {
          vm.urlValid = false
          vm.status = data['status']
        } else {
          vm.urlValid = false
          vm.status = 603
        }
      }).catch(function (err) {
        console.log('duration not ok;url')
        vm.urlValid = false
        vm.status = 604
        console.log(err)
        // return
      })
    }
  }
}

</script>
<style lang="css" scoped>
#uploadFile1{
  display:none;
  position: absolute;
  left: -9999px;
}
#uploadFile2{
  display:none;
  position: absolute;
  left: -9999px;
}
#uploadFile3{
  display:none;
  position: absolute;
  left: -9999px;
}
#uploadFileIntro{
  display:none;
  position: absolute;
  left: -9999px;
}
#uploadFileOutro{
  display:none;
  position: absolute;
  left: -9999px;
}
/* .teal{
  background-color: #5A29A3 !important;
} */
@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
      opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
      opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
      opacity: .2;
    }
}

.saving span {
    /**
     * Use the blink animation, which is defined above
     */
    animation-name: blink;
    /**
     * The animation should take 1.4 seconds
     */
    animation-duration: 1.4s;
    /**
     * It will repeat itself forever
     */
    animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;
}

.saving span:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .2s;
}

.saving span:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: .4s;
}
.greenText {
  color: #6dcc0e;
  cursor: pointer;
}
.tealText{
  color:#5A29A3;
  cursor: pointer;
}
</style>
