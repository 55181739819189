<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02393 6.96784C6.00078 7.25117 6 7.62345 6 8.2L6 14H18V8.2C18 7.62345 17.9992 7.25117 17.9761 6.96784C17.9539 6.69617 17.9162 6.59545 17.891 6.54601C17.7951 6.35785 17.6422 6.20487 17.454 6.10899C17.4045 6.0838 17.3038 6.04612 17.0322 6.02393C16.7488 6.00078 16.3766 6 15.8 6L8.2 6C7.62345 6 7.25117 6.00078 6.96784 6.02393C6.69617 6.04612 6.59546 6.0838 6.54601 6.10899C6.35785 6.20487 6.20487 6.35785 6.109 6.54601C6.0838 6.59545 6.04612 6.69617 6.02393 6.96784ZM4.03057 6.80497C4.06287 6.40963 4.13419 6.01641 4.32698 5.63803C4.6146 5.07354 5.07354 4.6146 5.63803 4.32698C6.01641 4.13419 6.40963 4.06287 6.80497 4.03057C7.17955 3.99997 7.63432 3.99998 8.16148 4L15.8385 4C16.3657 3.99998 16.8205 3.99997 17.195 4.03057C17.5904 4.06287 17.9836 4.13419 18.362 4.32698C18.9265 4.6146 19.3854 5.07354 19.673 5.63803C19.8658 6.01641 19.9371 6.40963 19.9694 6.80497C20 7.17955 20 7.63432 20 8.16148V16H4L4 8.16146C3.99998 7.63431 3.99997 7.17955 4.03057 6.80497Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00351 16.0143C4.00053 16.0796 4.00029 16.1693 4.00029 16.3334C4.00029 17.0177 4.00888 17.1638 4.03436 17.2589C4.12683 17.604 4.39638 17.8735 4.74147 17.966C4.83658 17.9915 4.98259 18 5.66695 18L18.3336 18C19.018 18 19.164 17.9915 19.2591 17.966C19.6042 17.8735 19.8737 17.604 19.9662 17.2589C19.9917 17.1638 20.0003 17.0177 20.0003 16.3334C20.0003 16.1693 20 16.0796 19.9971 16.0143C19.9969 16.0105 19.9967 16.007 19.9965 16.0038C19.9933 16.0036 19.9898 16.0034 19.986 16.0033C19.9207 16.0003 19.831 16 19.667 16L4.33362 16C4.16956 16 4.07987 16.0003 4.01454 16.0033C4.01077 16.0034 4.00726 16.0036 4.00403 16.0038C4.00386 16.007 4.00368 16.0105 4.00351 16.0143ZM2.00015 16.2658C1.99943 16.031 1.99856 15.7432 2.06844 15.4824C2.25337 14.7922 2.79247 14.2531 3.48265 14.0682C3.74344 13.9983 4.03125 13.9992 4.26608 13.9999C4.28913 14 4.31167 14 4.33362 14L19.667 14C19.6889 14 19.7114 14 19.7345 13.9999C19.9693 13.9992 20.2571 13.9983 20.5179 14.0682C21.2081 14.2531 21.7472 14.7922 21.9321 15.4824C22.002 15.7432 22.0011 16.031 22.0004 16.2658C22.0004 16.2889 22.0003 16.3114 22.0003 16.3334C22.0003 16.371 22.0003 16.4083 22.0004 16.4452C22.0009 16.9529 22.0014 17.391 21.8981 17.7765C21.6207 18.8118 20.812 19.6204 19.7767 19.8978C19.3912 20.0011 18.9532 20.0007 18.4455 20.0001C18.4086 20.0001 18.3713 20 18.3336 20L5.66695 20C5.6293 20 5.59202 20.0001 5.55511 20.0001C5.04739 20.0007 4.60936 20.0011 4.22383 19.8978C3.18855 19.6204 2.37991 18.8118 2.10251 17.7765C1.99921 17.391 1.99967 16.9529 2.00021 16.4452C2.00025 16.4083 2.00029 16.371 2.00029 16.3334C2.00029 16.3114 2.00022 16.2889 2.00015 16.2658Z"/>
  </svg>
</template>

<script>
export default {
  name: 'Laptop',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
